<template>
  <div v-if="preview">
    {{ previewTitle }}
  </div>
  <PreviewEditableCheckbox
    v-else
    v-model:open="isOpen"
    v-bind="$props"
    @change="(...args: [string, string?]) => $emit('change', ...args)"
    @update:open="$emit('update:open', $event)"
  />
</template>

<script lang="ts" setup>
import { map } from 'lodash-es'
import type { FieldOption, FieldCallback } from '#field/types'

const props = defineProps<{
  value: string | undefined
  label: string
  description?: string
  options: string
  preview?: boolean
  fieldId?: string
  disabled?: boolean
  showFullLabel?: boolean
  cellClass?: string
  disableSetting?: boolean
  width?: number
}>()

const isOpen = ref(false)

const emit = defineEmits<{
  (e: 'change', value: string, id?: string): void
  (e: 'set-callback', callback: Partial<FieldCallback>): void
  (e: 'update:open', value: boolean): void
}>()

const selectedValues = ref<string[]>(
  safeParseStringToJSON(props.value ?? '', [])
)

const menuOptions = computed<Array<FieldOption>>(() => {
  if (typeof props.options === 'string') {
    return safeParseStringToJSON(props.options, [])
  }

  return props.options
})

const selectedOptions = computed(() => {
  return menuOptions.value?.filter((option) =>
    selectedValues.value?.includes(option.value)
  )
})

const previewTitle = computed(() => {
  const labels = map(selectedOptions.value, 'label')
  return labels.join(', ')
})

defineExpose({
  focus: () => {
    isOpen.value = true
  },
})

onMounted(() => {
  emit('set-callback', {
    open: () => {
      isOpen.value = true
    },
  })
})
</script>
