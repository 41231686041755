<template>
  <div class="flex items-center justify-between px-4 pt-3 pb-2">
    <p class="font-semibold text-sm text-gray-900">{{ label }}</p>
    <UButton
      size="xs"
      icon="i-heroicons-x-mark"
      color="gray"
      variant="ghost"
      @click.prevent.stop="$emit('close')"
    />
  </div>
  <div class="px-4">
    <UInput
      v-model="search"
      icon="i-heroicons-magnifying-glass"
      size="sm"
      autofocus
      placeholder="Search option"
      :ui="{
        base: '!rounded-md',
      }"
    />
  </div>
  <div
    class="max-h-[20.175rem] overflow-y-auto scroll-stable minimal-scrollbar mt-4 pl-4 pr-2.5 pb-2 space-y-1"
  >
    <div
      v-for="(option, index) in filteredMenuOptions"
      ref="optionRefs"
      :key="option.value"
      :class="[
        'flex items-center text-gray-900 cursor-pointer rounded-md gap-2 w-full p-1',
        { 'bg-gray-100': currentFocusOption === index },
      ]"
      data-test="option.value"
      @mouseenter="currentFocusOption = index"
      @mouseleave="currentFocusOption = -1"
      @click="onSelect(option.value)"
    >
      <Checkbox :checked="selectedValues.includes(option.value)" />
      <Tooltip :text="option.label" arrow-class="!top-[unset]">
        <template #default="{ getTextRef }">
          <div
            class="px-2 py-1 rounded-md"
            :style="getFieldOptionPreset(option.color)"
          >
            <span
              :ref="getTextRef"
              class="text-sm line-clamp-1 break-all font-medium"
              data-test="option-item"
            >
              {{ option.label }}
            </span>
          </div>
        </template>
      </Tooltip>
    </div>
    <div v-if="!disableSetting && can('dashboard.data.manage_tasks_sections')" class="pt-2 space-y-2">
      <UButton
        class="w-full flex justify-center"
        variant="soft"
        color="gray"
        @click="setSettingTab('tasks')"
      >
        Manage options
      </UButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Checkbox } from '#components'
import type { FieldOption } from '#field/types'

const props = defineProps<{
  popoverRef: { ref: () => { open: boolean } }
  value?: string
  label?: string
  description?: string
  options: string
  preview?: boolean
  fieldId?: string
  disabled?: boolean
  showFullLabel?: boolean
  cellClass?: string
  disableSetting?: boolean
}>()

const { can } = useBoardAbility()
const { setSettingTab } = useBoardSettingsNavigator()

const optionRefs = ref<HTMLElement[]>([])
const currentFocusOption = ref(-1)
const search = ref('')
const isOpen = ref(props.popoverRef.ref().open)

const emit = defineEmits<{
  (e: 'change', value: string, id?: string): void
  (e: 'update:open', value: boolean): void
  (e: 'close'): void
}>()

const selectedValues = ref<string[]>(
  safeParseStringToJSON(props.value ?? '', [])
)

const menuOptions = computed<Array<FieldOption>>(() => {
  if (typeof props.options === 'string') {
    return safeParseStringToJSON(props.options, [])
  }

  return props.options
})

const filteredMenuOptions = computed(() => {
  return menuOptions.value?.filter((option) => {
    return option.label.toLowerCase().includes(search.value.toLowerCase())
  })
})

const onChange = (value: string[]) => {
  emit('change', JSON.stringify(value), props.fieldId)
}

const onSelect = (value: string) => {
  if (selectedValues.value?.includes(value)) {
    selectedValues.value = selectedValues.value.filter((v) => v !== value)
  } else {
    selectedValues.value.push(value)
  }

  onChange(selectedValues.value)
}

watch(
  () => props.value,
  (val) => {
    selectedValues.value = safeParseStringToJSON(val ?? '', [])
  }
)

const scrollToView = (index: number) => {
  if (optionRefs.value[index]) {
    optionRefs.value[index].scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
    })
  }
}

defineShortcuts({
  arrowup: {
    whenever: [isOpen],
    usingInput: true,
    handler: () => {
      if (currentFocusOption.value > 0) {
        currentFocusOption.value--
      } else {
        currentFocusOption.value = filteredMenuOptions.value?.length - 1
      }

      scrollToView(currentFocusOption.value)
    },
  },
  arrowdown: {
    whenever: [isOpen],
    usingInput: true,
    handler: () => {
      if (currentFocusOption.value < filteredMenuOptions.value?.length - 1) {
        currentFocusOption.value++
      } else {
        currentFocusOption.value = 0
      }

      scrollToView(currentFocusOption.value)
    },
  },
  enter: {
    whenever: [isOpen],
    usingInput: true,
    handler: () => {
      if (
        currentFocusOption.value >= 0 &&
        filteredMenuOptions.value![currentFocusOption.value]
      ) {
        onSelect(filteredMenuOptions.value[currentFocusOption.value].value)
      }
    },
  },
  escape: {
    whenever: [isOpen],
    usingInput: true,
    handler: () => {
      isOpen.value = false
    },
  },
})
</script>
