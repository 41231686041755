<template>
  <div
    :class="[
      'relative h-full w-full group/cell',
      {
        'cursor-not-allowed': disabled,
      },
    ]"
  >
    <DeferredPopover
      ref="popoverRef"
      :ui="{
        width: 'min-w-52 max-w-[280px]',
        wrapper: 'h-full',
        trigger: 'h-full flex items-center',
        base: 'pb-2',
      }"
      :disabled="disabled"
      v-bind="$attrs"
      @update:open="$emit('update:open', $event)"
    >
      <template #content>
        <PreviewPopoverContentCheckbox
          :popover-ref="popoverRef"
          v-bind="$props"
          @change="$emit('change', $event, fieldId)"
          @close="popoverRef.close()"
        />
      </template>
      <div
        :class="[
          'h-full w-full flex items-center py-1',
          cellClass,
          {
            '!cursor-not-allowed': disabled,
            active: popoverRef?.isOpen,
          },
        ]"
      >
        <div
          :class="[
            'w-full flex',
            showFullLabel
              ? 'overflow-y-auto scroll-stable max-h-20 minimal-scrollbar'
              : 'items-center',
          ]"
        >
          <ResizeLabels
            :labels="labels"
            :width="width"
            :get-label-style="(color: FieldOptionColor) => getFieldOptionPreset(color) "
            :show-full="showFullLabel"
          />

          <div
            class="absolute right-1 top-1/2 -translate-y-1/2 flex items-center"
          >
            <UButton
              v-if="selectedValues?.length && !disabled"
              color="gray"
              variant="ghost"
              size="2xs"
              icon="i-heroicons-x-mark"
              class="group-hover/cell:visible invisible"
              @click="onClear"
            />
            <Icon
              v-else
              name="heroicons:chevron-down"
              class="text-gray-500 group-hover/cell:visible invisible mr-1"
              size="2xs"
            />
          </div>
        </div>
      </div>
    </DeferredPopover>
  </div>
</template>

<script lang="ts" setup>
import type { FieldOptionColor } from '#field/constant'
import type { FieldOption, FieldLabel } from '#field/types'

const props = defineProps<{
  value?: string
  label?: string
  description?: string
  options: string
  preview?: boolean
  fieldId?: string
  disabled?: boolean
  showFullLabel?: boolean
  cellClass?: string
  disableSetting?: boolean
  width?: number
}>()

const emit = defineEmits<{
  (e: 'change', value: string, id?: string): void
  (e: 'update:open', value: boolean): void
}>()

const popoverRef = ref()

const selectedValues = ref<string[]>(
  safeParseStringToJSON(props.value ?? '', [])
)

const menuOptions = computed<Array<FieldOption>>(() => {
  if (typeof props.options === 'string') {
    return safeParseStringToJSON(props.options, [])
  }

  return props.options
})

const selectedOptions = computed(() => {
  return menuOptions.value?.filter((option) =>
    selectedValues.value?.includes(option.value)
  )
})

const labels = computed<FieldLabel[]>(() => {
  const labels = selectedOptions.value?.map((option) => {
    return {
      id: option.value,
      name: option.label,
      color: option.color as string,
    }
  })

  return labels || []
})

const onChange = (value: string[]) => {
  emit('change', JSON.stringify(value), props.fieldId)
}

const onClear = () => {
  selectedValues.value = []
  onChange([])
}

watch(
  () => props.value,
  (val) => {
    selectedValues.value = safeParseStringToJSON(val ?? '', [])
  }
)
</script>
